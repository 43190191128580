<style>
@media screen and (min-width: 1024px) {
  footer .want-to-talk {
    width: 50%;
  }

  footer .want-to-talk h2:not(.mobRes) {
    font-size: 4rem;
  }

  footer #social-media-icons {
    width: 40%;
  }
}
</style>

<template>
  <Animation3 />

  <!-- SEO Tags -->
  <teleport to="head">
    <meta name="description" :content="Seo.Description" />
    <meta name="keywords" :content="Seo.Keywords" />
    <link rel="alternate" hreflang="x-default" :href="Seo.HreflangDefault" />
    <link rel="alternate" hreflang="en-US" :href="Seo.HreflangCountry" />

    <!-- Open Graph Tags -->
    <meta property="og:title" :content="Seo.OpenGraph.Title" />
    <meta property="og:site_name" :content="Seo.OpenGraph.SiteName" />
    <meta property="og:url" :content="Seo.OpenGraph.Url" />
    <meta property="og:description" :content="Seo.OpenGraph.Description" />
    <meta property="og:type" :content="Seo.OpenGraph.Website" />
    <meta property="og:image" :content="Seo.OpenGraph.Image" />
    <!-- ./Open Graph Tags -->

    <!-- Twitter Card Tags -->
    <meta name="twitter:card" :content="Seo.TwitterCard.Card" />
    <meta name="twitter:site" :content="Seo.TwitterCard.Site" />
    <meta name="twitter:title" :content="Seo.TwitterCard.Title" />
    <meta name="twitter:description" :content="Seo.TwitterCard.Description" />
    <meta name="twitter:image" :content="Seo.TwitterCard.Image" />
    <!-- ./Twitter Card Tags -->
  </teleport>
  <!-- ./SEO Tags -->

  <div id="contact" class="page">
    <div class="flex margin container">
      <div class="contactBanner">
        <h1 data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000">
          {{ content.Mainheading }}
        </h1>
        <p data-aos="fade-up" data-aos-delay="1500" data-aos-duration="1500">
          <span v-if="siteLanguage == 'GR'" style="font-size: 2.0rem;">
            {{ content.ShortDescription }}

            <br />
            <br />
          </span>
          {{ content.Description }}
        </p>
      </div>
      <!--        
       <h1> {{content.ShortDescription}} </h1> -->
    </div>
    <section class="vr">
      <div class="top"></div>
      <div class="bottom"></div>
    </section>
    <section class="project container px-2">
      <div class="flex">
        <h2>{{ content.ContactFormHeading }}</h2>

        <CustomToggle
          class="custom-toggle-class"
          :label="content.NewsletterOptionText"
        />
      </div>
      <br />
      <br />
      <form @submit.prevent="submitForm" method="post" ref="anyName">
        <div class="grid-container">
          <Input
            name="name"
            id="name"
            v-model="name"
            type="text"
            :placeholder="content.ContactFormFieldPlaceHolders.fullName"
            required="true"
          />
          <Input
            name="email"
            id="email"
            v-model="email"
            type="text"
            :placeholder="content.ContactFormFieldPlaceHolders.Email"
            required="true"
            :invalidInputMessage="content.ContactFormMessages.InvalidEmail"
          />
          <Input
            name="phone_number"
            id="phone"
            v-model="phone_number"
            type="text"
            :placeholder="content.ContactFormFieldPlaceHolders.Phone"
            :invalidInputMessage="content.ContactFormMessages.InvalidPhone"
            required="false"
          />

          <Input
            name="location"
            id="location"
            v-model="location"
            type="text"
            :placeholder="content.ContactFormFieldPlaceHolders.address"
            required="false"
          />
          <Input
            name="company"
            id="company"
            v-model="company"
            type="text"
            required="false"
            :placeholder="content.ContactFormFieldPlaceHolders.company"
          />
          <Input
            name="website"
            id="website"
            v-model="website"
            type="text"
            required="false"
            :placeholder="content.ContactFormFieldPlaceHolders.website"
            :invalidInputMessage="content.ContactFormMessages.InvalidWebsiteUrl"
          />
          <TextArea
            name="message"
            id="message"
            v-model="message"
            type="textarea"
            required="true"
            :placeholder="content.ContactFormFieldPlaceHolders.message"
          />
          <div class="bottom-section">
            <FormSubmitBtn
              class="submit-btn  "
              :name="content.FormButtonText"
              type="submit"
              :disable="disable"
            />
          </div>
        </div>

        <!-- <div>
          <h3>Data retrieved from server:</h3>
          <p v-if="success">{{ success }}</p>
          <pre>{{ response }}</pre>
        </div>-->
      </form>
    </section>
  </div>
</template>

<script>
import Input from "@/components/Input";

import CustomToggle from "@/components/CustomToggle";
// import Button from "@/components/Button";

import FormSubmitBtn from "@/components/FormSubmitBtn";
import constant from "../config/constants"; //find all the api urls, assets, images path here

import Animation3 from "@/components/Animation3";

import axios from "axios";
import { notification } from "ant-design-vue";
import { LanguageStrings } from "@/languages";
import { SeoStrings } from "@/seo";
import TextArea from "@/components/TextArea";

export default {
  components: {
    Input,

    // Button,
    CustomToggle,
    Animation3,
    FormSubmitBtn,
    TextArea,
  },

  data() {
    return {
      siteLanguage: localStorage.getItem("siteLanguage"),
      content: null,
      Seo: {},
      name: "",
      email: "",
      location: "",
      website: "",
      company: "",
      phone_number: "",
      message: "",
      response: "",
      success: "",
      activeClass: "active",
      disable: false,
      phone: "",
    };
  },

  methods: {
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validWebsite: function(website) {
      var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return regexp.test(website);
    },

    validPhone: function(phone_number) {
      var ph = /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/im;
      return ph.test(phone_number);
    },

    openNotificationWithIcon(type) {
      notification[type]({
        message: `${type}`,
        description:
          type === "error"
            ? this.errors
            : this.content.ContactFormMessages.SuccessMessage,
      });
      setTimeout(() => {
        this.disable = false;
      }, 6000);
    },
    submitForm(e) {
      e.preventDefault();
      this.disable = true;
      setTimeout(() => {
        this.disable = false;
      }, 6000);
      if (
        this.name === "" ||
        this.email === "" ||
        this.message === "" ||
        this.phone_number === ""
      ) {
        this.errors = this.content.ContactFormMessages.AllFieldsRequired;
        this.openNotificationWithIcon("error");
      } else if (!this.validEmail(this.email)) {
        this.errors = this.content.ContactFormMessages.InvalidEmail;
        this.openNotificationWithIcon("error");
      } else if (this.validPhone(this.phone_number) == false) {
        this.errors = this.content.ContactFormMessages.InvalidPhone;
        this.openNotificationWithIcon("error");
      } else if (this.website != "" && !this.validWebsite(this.website)) {
        this.errors = this.content.ContactFormMessages.InvalidWebsiteUrl;
        this.openNotificationWithIcon("error");
      } else {
        axios
          .post(constant.API_URL + "contact", {
            name: this.name,
            email: this.email,
            address: this.location,
            website_url: this.website,
            company: this.company,
            country: this.location,
            phone_no: this.phone_number,
            project_summary: this.message,
            siteLanguage: this.siteLanguage,
          })
          .then((response) => {
            // this.success = "Data saved successfully";
            this.response = JSON.stringify(response, null, 2);

            if (response.data.status_code == "200") {
              this.openNotificationWithIcon("success");
              this.name = "";
              this.email = "";
              (this.location = ""),
                (this.website = ""),
                (this.company = ""),
                (this.phone_number = ""),
                (this.message = "");

              this.$refs.anyName.reset();
            } else {
              notification.open({
                message: "Sorry, Error Occured",
                description: response.data.message.email,
              });

              // this.openNotificationWithIcon("error", response.data.message.email)
            }
          })
          .catch((error) => {
            this.response = "Errors: " + error.response;

            this.errors = this.content.ContactFormMessages.ServerError;

            // this.errors = "Sorry, Server Error Occured. Please Try Again later";

            this.openNotificationWithIcon("error");
          });
      }
    },
  },

  created() {
    localStorage.getItem("siteLanguage") === "GR"
      ? ((this.content = LanguageStrings.gr.ContactPage),
        (this.Seo = SeoStrings.gr.Contact))
      : ((this.content = LanguageStrings.en.ContactPage),
        (this.Seo = SeoStrings.en.Contact));

    document.querySelector("head title").innerText = this.content.MetaTitle;
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
